/*----------------------------------------------------------*\
    Mixins

    This file contains custom mixins. All mixins should go
    here as this file is imported into both the base and
    main css files.

\*----------------------------------------------------------*/


/*------------------------------------*\
    @group Social Media Link Colors
\*------------------------------------*/

@mixin link-colors ($color: null, $bg: null) {
    background-color: $bg;
    color: $color;

    &:hover,
    &:focus {
        @if ($bg) {
            background-color: darken($bg, 10%);
        }

        @if ($color and $bg==null) {
            //color: darken($color,10%);
        }
    }
}

/* @end Social Media Link Colors */

/*------------------------------------*\
    @group Hero image Sizes
\*------------------------------------*/
@mixin hero-image($filename, $extension: jpg) {
    background-image: url('/images/hero/bg_'+ $filename + '.'+ $extension);

    @media screen and #{breakpoint(medium)} {
        & {
            background-image: url('/images/hero/bg_'+ $filename + '_med.'+ $extension);
        }
    }

    @media screen and #{breakpoint(large)} {
        & {
            background-image: url('/images/hero/bg_'+ $filename + '_lrg.'+ $extension);
        }
    }
}

/* @end Hero Images */

/*------------------------------------*\
    @group Font Sizes
\*------------------------------------*/
@mixin font-size($font-size) {
    $item: map-get($font-sizes, $font-size);
    font-size: nth($item, 1);
    line-height: nth($item, 2);
}

/* @end Font Sizes */

/*------------------------------------*\
    @group Hero Slider images
\*------------------------------------*/
@mixin hero-slider-image($filename, $backgroundcolor, $extraparams: '') {
    background-image: url($filename + '?w=500&auto=format');
    background-color: $backgroundcolor;

    @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
        only screen and (-o-min-device-pixel-ratio: 13/10),
        only screen and (min-resolution: 120dpi) {
        & {
            background-image: url($filename + '?w=1000&auto=format&q=20' + $extraparams);
        }
    }

    @include breakpoint(500px) {
        & {
            background-image: url($filename + '?w=1000&auto=format' + $extraparams);
        }

        @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
        only screen and (-o-min-device-pixel-ratio: 13/10),
        only screen and (min-resolution: 120dpi) {
            & {
                background-image: url($filename + '?w=2000&auto=format&q=20' + $extraparams);
            }
        }
    }

    @include breakpoint(1000px) {
        & {
            background-image: url($filename + '?w=2000&auto=format' + $extraparams);
        }

        @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
        only screen and (-o-min-device-pixel-ratio: 13/10),
        only screen and (min-resolution: 120dpi) {
            & {
                background-image: url($filename + '?w=4000&auto=format&q=20' + $extraparams);
            }
        }
    }
}

/* @end Hero Images */