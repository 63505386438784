.alert-bar {
	background-color: $brand;
	color: $white;
	text-align: center;
	text-shadow: $text-shadow;
	padding: 13px 0 10px;
	.inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	p {
		@include font-size(small);
		margin-right: 10px;
		margin-bottom: 10px;
	}
	a {
		font-weight: $main-bold;
		color: $white;
		border-bottom: solid 2px $white;
		transition: color .3s, border-color .3s;
		&:hover, &:focus {
			color: $brand-accent;
			border-color: $brand-accent;
		}
	}
}

@include breakpoint(medium) {
	.alert-bar {
		padding: 12px 0 3px;
	}
}