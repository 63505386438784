.page-header {
	position: relative;
	padding: 10px 0 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
	.logo {
		padding-right: 10px;
		grid-area: page-header-logo;
		img {
			margin: 0;
		}
	}
	.main-actions {
		font-size: rem-calc(14);
		font-family: $main-font;
		font-weight: $main-normal;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		grid-area: page-header-actions;
		.item {
			line-height: 1.1;
			margin-left: 10px;
			margin-bottom: 10px;
			&:first-child {
				margin-left: 0;
			}
			&.search-item {
				margin-left: 15px;
			}
			&.gc-item {
				margin-left: 15px;
			}
		}
		.social-item {
			margin-left: 15px;
			display: flex;
			align-items: center;
			gap: 12px;
		}
		.divider {
			padding-left: 10px;
			border-left: solid 2px #ede8e5;;
		}
		img {
			margin: 0;
		}
		.icon {
			display: none;
		}
		.extended-text {
			display: none;
		}
		.header-button {
			background-color: $brand-alt;
			color: $white;
			line-height: 1;
			border-radius: 3px;
			padding: 7px 10px;
			display: inline-flex;
			align-items: center;
			gap: 10px;
			.icon {
				margin: 0;
			}
			&:hover, &:focus {
				background-color: $brand;
			}
		}
	}
	.search-trigger {
		height: 28px;
		color: $brand;
		margin-left: 5px;
		border: 1px solid $tan;
		padding: 4px 10px;
		border-radius: 3px;
		cursor: pointer;
		display: inline-flex;
		align-items: center;
		gap: 10px;
		transition: color .3s;
		&:hover, &:focus {
			color: $brand-alt;
		}
		.search-trigger-icon {
			color: $brand-alt;
			display: flex;
			align-items: center;
			svg {
				height: 14px;
				width: 14px;
			}
		}
		.search-trigger-text {
			font-size: rem-calc(16);
			font-weight: $main-normal;
			line-height: 1;
		}
	}
	.nav-toggle {
		grid-area: page-header-nav-toggle;
		justify-self: end;
		align-self: center;
	}
}

.page-header .inner {
	display: grid;
	grid-template-areas: "page-header-actions page-header-actions"
						 "page-header-logo page-header-nav-toggle";
}

@include breakpoint(600px) {
	.page-header {
		.main-actions {
			font-size: rem-calc(16);
			.extended-text {
				display: inline-block;
			}
		}
		.search-trigger {
			height: 30px;
		}
	}
}

@include breakpoint(750px) {
	.page-header {
		.main-actions {
			.icon {
				margin-right: 8px;
				display: flex;
				align-items: center;
				img {
					width: auto;
					height: 14px;
				}
			}
			.item {
				display: flex;
				align-items: center;
			}
		}
	}
}

@include breakpoint(large) {
	.page-header {
		.main-actions {
			justify-content: flex-end;
		}
	}
}