.heading-callout {
	background-color: $brand;
	color: $white;
	border-radius: 5px;
	margin-bottom: 75px;
	overflow: hidden;
	.callout-heading {
		background-color: #27508a;
		padding: 22px 20px;
		img {
			margin: 0 8px 0 0;
		}
	}
	h2 {
		font-size: rem-calc(20);
		color: $white;
		line-height: 1.25;
		margin: 0;
		display: flex;
		align-items: center;
	}
	.callout-text {
		padding: 20px;
		.button {
			margin-bottom: 0;
		}
	}
}

.callout-overlap-bar {
	background: $brand;
	font-size: rem-calc(16);
	color: $white;
	.inner {
		max-width: 715px;
	}
	h2 {
		color: $white;
		.icon {
			color: $light-blue;
		}
	}
	p {
		margin-bottom: 10px;
	}
	.text {
		max-width: 340px;
		padding: 32px 0;
		margin: 0 auto;
	}
	.image {
		text-align: center;
		img {
			width: 100%;
			max-width: 340px;
			margin: 0;
		}
	}
}

@include breakpoint(medium) {
	.callout-overlap-bar {
		.inner {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 30px;
		}
		.image {
			position: relative;
			img {
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}
	}
}

// Simple callout
.simple-callout {
	max-width: 500px;
	background: $white;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0,0,0,.05);
	> *:last-child {
		margin-bottom: 0;
	}
	.heading {
		font-size: rem-calc(20);
		color: $main-color;
		margin-bottom: 10px;
	}
	p {
		font-size: 1rem;
	}
}

// Gift card callout
.gift-card-callout {
	container-name: giftCardCalloutContainer;
	container-type: inline-size;
	margin: 40px 0 30px;
	h2 {
		margin-bottom: 20px;
	}
}


@container giftCardCalloutContainer (min-width: 450px) {
	.gift-card-callout {
		.content {
			display: flex;
			gap: 18px;
		}
		.image {
			flex-basis: 41%;
			order: 1;
		}
		.text {
			flex-basis: 59%;
		}
    }
}

// Callout with centered text and icon
.callout-center-text {
	max-width: 700px;
	font-size: 1rem;
	text-align: center;
	margin: 0 auto;
	.heading {
		font-size: rem-calc(24);
		color: $brand;
		line-height: 1.25;
		margin-bottom: 10px;
	}
	.header-icon {
		margin-bottom: 6px;
	}
}