.grid-divider {
	max-width: 400px;
	margin: 0 auto 40px;
	> div {
		border-bottom: solid 2px $tan;
		margin-bottom: 20px;
		&:last-child {
			border-bottom: 0;
			margin-bottom: 0;
		}
	}
}

@include breakpoint(medium) {
	.grid-divider-3 {
		max-width: 100%;
		display: flex;
		> div {
			flex-basis: 31%;
			padding-right: 20px;
			padding-left: 20px;
			border-bottom: 0;
			margin-bottom: 0;
			border-right: solid 2px $tan;
		}
		> div:first-child {
			padding-left: 0;
		}
		> div:last-child {
			border-right: 0;
		}
	}
}