.promotion-overview-intro {
	margin-bottom: 33px;
	h1 {
		margin-bottom: 20px;
	}
	p {
		font-size: rem-calc(20);
		line-height: 1.33;
		@include breakpoint(medium) {
			font-size: rem-calc(24);
		}
	}
}