.location-cards {
	margin-bottom: 60px;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 520px));
	grid-gap: 30px;
	justify-content: center;
}

.location-card {
	background: $white;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
	border-radius: $border-radius;
	overflow: hidden;
	.text {
		padding: 30px 20px 7px;
	}
	.store-info {
		.col > p:last-child {
			margin-bottom: 30px;
		}
		@include breakpoint(1650px) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}
	.icon {
		line-height: 1;
		display: flex;
		align-items: center;
		span {
			color: $brand-alt;
			margin-right: 10px;
		}
	}
	.button {
		margin-bottom: 30px;
	}
	img {
		margin: 0;
	}
	h2 {
		color: $main-color;
	}
	hr {
		margin-top: 0;
	}
}

.location-card.location-callout {
	background: none;
	box-shadow: none;
	h2 {
		color: $white;
	}
}

@include breakpoint(medium) {
	.location-cards {
		grid-template-columns: repeat(2, 1fr);
	}
}

@include breakpoint(large) {
	.location-cards {
		grid-template-columns: repeat(3, 1fr);
	}
	.location-card {
		max-width: 520px;
	}
}