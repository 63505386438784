/*----------------------------------------------------------*\
   Tables

	The majority of the table styling comes from the
    Foundation settings file.

\*----------------------------------------------------------*/

table {
	margin-bottom: 2rem;
	border-collapse: collapse;
	th {
		font-size: 1.125rem;
		padding: 5px 10px;
		border: solid 1px $tan;
		text-align: left;
		vertical-align: top;
	}
	td {
		padding: 5px 10px;
		border: solid 1px $tan;
		vertical-align: top;
	}
	img {
		margin: 10px 0;
	}
}

.table {
	width: 100%;
	max-width: fit-content;
	border-collapse: collapse;
	border-radius: $border-radius;
	display: block;
	overflow-x: auto;
	white-space: nowrap;
	th {
		background: #E9E4D8;
		font-size: rem-calc(16);
		font-family: $heading-font;
		font-weight: $heading-normal;
		color: $brand;
		padding: 10px 20px;
		text-align: left;
		border: none;
	}
	td {
		background: $white;
		font-size: rem-calc(16);
		padding: 5px 20px;
		border: none;
	}
	tr:first-child > td {
		padding-top: 20px;
	}
	tr:last-child > td {
		padding-bottom: 20px;
	}
}

.table.alt-rows {
	td {
		padding: 13px 20px;
	}
	tr:nth-child(even) td {
		background-color: #FAF9F6;
	}
}

@include breakpoint(850px) {
	.table {
		max-width: 100%;
		overflow: hidden;
		white-space: normal;
		display: table;
	}
}