/*----------------------------------------------------------*\
   Icon

   Default icon styling and sprite icon styles.

\*----------------------------------------------------------*/

.inline-icon {
	line-height: 1;
	display: flex;
	align-items: center;
	span {
		color: $brand-alt;
		margin-right: 10px;
	}
}