.media-highlight {
	max-width: 715px;
	margin: 0 auto 40px;
}

@include breakpoint(800px) {
	.media-highlight.inline {
		max-width: 100%;
		display: flex;
		.media {
			flex-basis: 50%;
			padding-left: 2%;
		}
		.text {
			flex-basis: 50%;
			padding-right: 2%;
		}
	}
}