/*----------------------------------------------------------*\
    Basestyles

    Base styles that are too small for individual files. The
    animate classes listed here will be the default for
    animated elements unless overriden.

\*----------------------------------------------------------*/

body {
	width: 100%;
	background: $white;
	font-family: $main-font;
	@include font-size(default);
	font-weight: $main-normal;
	color: $main-color;
	position: relative;
}

body.offscreen-nav-visible {
	position: fixed;
	&::before {
		content: '';
		width: 100%;
		height: 100%;
		background: #022346;
		opacity: .95;
		z-index: 1500;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
}

p {
	font-size: 1em;
	line-height: 1.5;
	margin: 0 0 22px;
	&.large {
		@include font-size(large);
		margin-bottom: 27px;
	}
	&.small {
		@include font-size(small);
		margin-bottom: 14px;
	}
	&.normal {
		@include font-size(default);
		margin-bottom: 24px;
	}
}
a {
	color: $link-base;
	transition: all .3s;
	text-decoration: none;
	position: relative;
	&:hover,
	&:focus {
		color: $link-active;
		text-decoration: none;
	}
}

a.arrow-link {
	font-family: $main-font;
	font-size: rem-calc(18);
	font-weight: $main-bold;
	display: inline-flex;
	align-items: flex-end;
	&::after {
		content: url('/images/cssimg/arrow_right.svg');
		margin-left: 5px;
	}
}

a.arrow-link.light {
	color: $light-blue;
	&::after {
		content: url('/images/cssimg/arrow_right_light_blue.svg');
	}
	&:hover, &:focus {
		color: $white;
	}
}

a.alt {
	color: $brand;
	&:hover, &:focus {
		color: lighten($brand, 10%);
	}
}
hr {
	background-color: transparent;
	height: 1px;
	border-top: solid 2px #dfdbd8;
	border-bottom: solid 2px #dfdbd8;
	margin: 40px 0;
}

hr.single {
	height: 0;
	border-bottom: 0;
	margin: 30px 0;
}

.inner {
	@include xy-grid-container;
}

.inner-wide {
	max-width: 1600px;
}

/* @end */