// Formstack styling
#fsLocal.garden-coach-payment-template {
	.pre-footer .arrow-link,
	.page-footer .button,
	.header-button {
		color: $white;

	}
	.alert-bar a {
		color: $white;
	}
	.page-footer .arrow-link,
	.page-sub-footer a,
	.main-nav a,
	.offscreen-nav a {
		color: $brand;
	}
}
.isdHqD {
	display: block;
}
.fsBody {
	padding: 0;
}

#fsLocal {
	padding: 0;
	.fsLabel {
		opacity: 1;
	}
}
.formstack-garden-coach {
	max-width: 800px;
	background: $white;
	padding: 30px;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0,0,0,.05);
	margin: 0 0 32px;
	.fsBody, .fsBody input, .fsBody select, .fsBody textarea {
		font-family: inherit;
	}
	input[type="text"],
	input[type="email"],
	select,
	textarea {
		height: 42px;
	}
	.fsBody {
		padding: 0;
		.fsForm {
			background: none;
			padding: 0;
			margin: 0;
		}
	}
	// Inner form wrapper
	.InnerFormBody-sc-o0t3bv-0.hjaXRz {
		padding: 0;
	}
	.fsSubmitButton {
		background-color: $brand !important;
		font-family: $main-font;
		font-weight: $alt-bold;
		color: $white;
		line-height: 1.2;
		border: solid 2px $white;
		border-radius: 22px !important;
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35);
	    text-decoration: none;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
	    padding: 9px 23px;
	    margin: 0 0 1.25rem;
		transition: background-color 0.3s ease 0s;
		display: inline-block;
		&::after {
			display: none;
		}
		&:hover,
		&:focus {
			background: $brand-alt !important;
			color: $white;
			cursor: pointer;
		}
	}
	.fsSection {
		// display: grid;
		display: block;
		// grid-template-columns: 1fr;
		// grid-column-gap: 30px;
	}
	#fsSection0 {
		.garden-coach-payment-heading {
			margin-top: 0;
		}
	}
	// Gift card amount field
	#fsRow4206787-1,
	#fsRow4607338-1,
	#fsRow4607340-1 {
		grid-column: 1 / -1;
		.fsCell {
			max-width: 200px;
		}
		.fsCurrency {
			font-size: rem-calc(18);
		}
	}
	// To and From fields
	#fsRow4206787-2,
	#fsRow4607340-3,
	#fsRow4607338-2,
	#fsRow4607338-3,
	#fsRow4607340-3,
	#fsRow4607340-2 {
		margin-bottom: 0 !important;
	}
	#fsRow4206787-3 {
		margin-bottom: 0 !important;
	}
	#fsRow4206787-4,
	#fsRow4607338-4,
	#fsRow4607340-4 {
		grid-column: 1 / -1;
	}
	// Ship To label row
	#fsRow4206787-5,
	#fsRow4607340-5,
	#fsRow4607338-5 {
		grid-column: 1 / -1;
	}
	#fsRow4206787-6 {
		grid-column: 1 / -1;
		.fsSubField.fsFieldAddress,
		.fsSubField.fsFieldAddress2 {
			margin-bottom: 26px;
		}
	}
	// Address to ship to row
	#fsRow4206787-7,
	#fsRow4607340-7,
	#fsRow4607338-7 {
		grid-column: 1 / -1;
	}
	// Your information heading row
	#fsRow4206787-8,
	#fsRow4607338-8,
	#fsRow4607340-8 {
		grid-column: 1 / -1;
		margin-bottom: 0 !important;
	}
	// Phone row
	#fsRow4206787-11,
	#fsRow4607340-11,
	#fsRow4607338-11 {
		grid-column: 1 / -1;
	}
	// Billing address section
	#fsRow4206787-12,
	#fsRow4607338-12,
	#fsRow4607340-12 {
		grid-column: 1 / -1;
	}
	// Payment heading row
	#fsRow4206787-13,
	#fsRow4607338-13,
	#fsRow4607340-13 {
		grid-column: 1 / -1;
		margin-bottom: 0 !important;
		#fsCell104198098 {
			margin-bottom: 0 !important;
		}
	}
	// CC field
	#fsRow4206787-14,
	#fsRow4607340-14,
	#fsRow4607338-14 {
		grid-column: 1 / -1;
		.fsFieldCell {
			max-width: 600px;
		}
	}
	// Shipping options field
	#fsRow4206787-15,
	#fsRow4607338-15,
	#fsRow4607340-15 {
		grid-column: 1 / -1;
		.fsCurrencyPrefix {
			font-size: rem-calc(32);
			color: $brand;
			margin-top: 10px;
		}
		.fsLabel {
			color: $brand;
			opacity: 1;
		}
		.fsRequiredMarker {
			display: none;
		}
	}
	// Total field
	#fsRow4206787-16,
	#fsRow4607340-16,
	#fsRow4607338-16 {
		grid-column: 1 / -1;
		margin-bottom: 0 !important;
		#field104438883_1 {
			top: 7px;
		}
		label {
			font-family: $main-font;
			font-weight: $main-normal;
		}
	}
	// Name row
	#fsRow4206787-9,
	#fsRow4206787-10,
	#fsRow4206787-17,
	#fsRow4607340-6,
	#fsRow4607340-9,
	#fsRow4607340-10,
	#fsRow4607340-17,
	#fsRow4607338-6,
	#fsRow4607338-9,
	#fsRow4607338-10,
	#fsRow4607338-17 {
		grid-column: 1 / -1;
	}
	.fsCreditcardFieldContainer {
		align-items: flex-start;
	}
	.fsCreditcardNumberField .fsCreditcardNumberField-icon {
		top: calc(50% - 18px);
	}
	// Section labels (e.g. Ship To)
	#fsRow4206787-5,
	#fsRow4607338-5,
	#fsRow4607340-5,
	#fsRow4206787-7,
	#fsRow4607338-7,
	#fsRow4607340-7,
	#fsRow4206787-8,
	#fsRow4607338-8,
	#fsRow4607340-8,
	#fsRow4607338-8,
	#fsRow4206787-12,
	#fsRow4607340-12,
	#fsRow4607338-12,
	#fsRow4206787-13,
	#fsRow4607340-13,
	#fsRow4607338-13 {
		margin-bottom: 0 !important;
		p {
			font-family: $heading-font;
			font-size: rem-calc(24);
			font-weight: $heading-bold;
			color: $brand;
			margin: 0;
		}
	}
	.fsForm select:not([multiple="multiple"]) {
		line-height: 1.5;
		padding-right: 5px;
	}
	.garden-coach-payment-heading {
		font-family: $heading-font;
		font-size: rem-calc(20);
		font-weight: $heading-bold;
		color: $brand;
		margin: 20px 0 0;
	}
	.fsLabelVertical .fsLabel {
		margin-bottom: 0;
	}
	.fsBody.fsSupporting,
	.fsSupporting {
		font-weight: $main-normal;
	}
	input + .fsSupporting,
	select + .fsSupporting {
		font-weight: $main-normal;
		margin-top: -17px;
	}
	.fsRowBody input[type="radio"], .fsRowBody input[type="checkbox"] {
		top: 0;
	}
	.fsRowBody input[type="text"].fsRequired,
	.fsRowBody input[type="email"].fsRequired,
	.fsRowBody input[type="number"].fsRequired,
	.fsRowBody input[type="tel"].fsRequired,
	.fsForm select.fsRequired,
	.fsForm textarea.fsRequired,
	.fsRowBody input[type="text"],
	.fsRowBody input[type="email"],
	.fsRowBody input[type="number"],
	.fsRowBody input[type="tel"],
	.fsForm select,
	.fsForm textarea  {
		margin-top: 5px;
	}
	.fsForm .fsSubmit {
		text-align: left;
		margin-top: 0;
		padding-top: 5px;
		padding-bottom: 5px;
	}
	.fsSubmitButton {
		background-color: $brand;
		font-family: $main-font;
		font-weight: $alt-bold;
		font-size: rem-calc(24) !important;
		color: $white;
		line-height: 1.2;
		border: solid 2px $white;
		border-radius: 22px;
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35);
	    text-decoration: none;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
	    padding: 15px 65px;
	    border-radius: 65px !important;
	    margin: 0 0 1.25rem;
		transition: background-color 0.3s ease 0s;
		display: inline-block;
		margin-bottom: 0;
		&:hover,
		&:focus {
			background: $brand-alt;
			color: $white;
			cursor: pointer;
		}
	}
	// Total amount input style
	#field10419809,
	#field117913531,
	#field117913478,
	#field104198097 {
		background: none;
		font-size: rem-calc(32);
		color: $brand;
		opacity: 1;
		border: none;
	}
	.fsFieldFocused:not(.fsValidationError).fsSpan100 {
		padding: inherit !important;
		margin: inherit !important;
		margin-bottom: 0 !important;
	}
	.fsLabel {
		font-family: $alt-font;
		font-weight: $alt-bold;
	}
	// Billing address label, adds space between same as shipping checkbox
	#label104198099,
	#label117913474,
	#label117913527 {
		margin-top: 20px;
	}
	#fsCell104438883 {
		margin-bottom: 20px !important;
	}
	.fsSubField,
	.fsSubFieldGroup {
		margin-bottom: 20px;
		input, select {
			margin-bottom: 10px;
		}
		label {
			font-family: $main-font;
			font-weight: normal;
		}
	}
	// Garden Coach Fields
	.garden-coach-payment-heading {
		margin-bottom: 10px;
	}
	.garden-coach-payment-body {
		font-size: rem-calc(16);
		line-height: 1.5;
	}
	.fsLabelVertical {
		input {
			margin-bottom: 10px;
		}
	}
	.fsBody {
		.fsRow,
		.fsRowBody {
			margin-bottom: 0 !important;
		}
		// Address section
		// Phone field
		// Name field
		// Billing address section
		#fsRow4871250-9,
		#fsRow4871250-13,
		#fsRow4871250-11,
		#fsRow4871250-14 {
			margin-bottom: 20px !important;
		}
		// Same as checkbox
		#fsRow4871250-14 {
			margin-top: 20px;
		}
	}
	// I understand field
	#fsCell127372332 .garden-coach-payment-body {
		margin-bottom: 5px;
	}
	// Total field
	#fsRow4871250-4,
	#fsRow4871250-16 {
		grid-column: 1 / -1;
		margin-bottom: 0 !important;
		#field104438883_1 {
			top: 7px;
		}
		label {
			font-family: $main-font;
			font-weight: $main-normal;
		}
	}
	// Total amount input style
	#field127372180,
	#field127367216 {
		background: none;
		font-size: rem-calc(32);
		color: $brand;
		opacity: 1;
		border: none;
	}
	// I understand that my credit card will be charged for the following amount: field styling
	#label-field127372332 .garden-coach-payment-body {
		margin-bottom: 0;
	}

	// Total field $ styling
	#label-field127372180,
	#label-field127367216 {
		.StyledSubFieldGroup-sc-ik27pq-0.gfzGQs {
			align-items: flex-start;
		}
		.StyledCurrency-sc-yjeyan-0.ja-DBtJ {
			font-size: 32px;
			justify-content: flex-start;
			align-items: flex-start;
			margin-top: 5px;
			margin-right: 5px;
			@include breakpoint(580px) {
				margin-right: 0;
			}
		}
	}
	#label127372180,
	#label-field127531232,
	.StyledLabelText-sc-1bsmirz-0,
	#label-field127372180 .fsRequiredLabel {
		display: none;
	}
	// Privacy policy agree
	#fsRow4871250-17 {
		margin-bottom: 20px !important;
	}
	// Main form heading
	#fsCell127367205 .garden-coach-payment-heading {
		margin-top: 0;
	}
	// Your information heading {
	#fsCell127372676 .garden-coach-payment-heading {
		// margin-bottom: 0;
	}
	// Phone row
	#fsRow4871250-13 {
		margin-top: 0;
	}
	// Submit button
	// #fsSubmit4871250 {
	// 	margin-top: 20px;
	// }
	// Same as billing
	.StyledOptionLabel-sc-1bymt4x-0.ciGnYU {
		margin: 20px 0;
	}
}

@include breakpoint(650px) {
	.wf-active .formstack-gift-card {
		.fsSection {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 30px;
		}
		// Your information heading row
		#fsRow4206787-8,
		#fsRow4607338-8,
		#fsRow4607340-8 {
			.fsNameFirst {
				width: 50%;
				padding-right: 15px;
				margin-right: 0;
			}
			.fsNameLast {
				padding-left: 15px;
			}
		}
	}
}