@font-face {
  font-family: 'ff-meta-serif-web-pro';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('/fonts/meta_serif_book.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/meta_serif_book.woff') format('woff'), /* Modern Browsers */
       url('/fonts/meta_serif_book.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'ff-meta-serif-web-pro';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('/fonts/meta_serif_bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/meta_serif_bold.woff') format('woff'), /* Modern Browsers */
       url('/fonts/meta_serif_bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'ff-meta-web-pro';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('/fonts/meta_web_book.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/meta_web_book.woff') format('woff'), /* Modern Browsers */
       url('/fonts/meta_web_book.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'ff-meta-web-pro';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('/fonts/meta_web_bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/meta_web_bold.woff') format('woff'), /* Modern Browsers */
       url('/fonts/meta_web_bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* dm-serif-text-regular - latin */
@font-face {
  font-family: 'DM Serif Text';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/dm-serif-text-v4-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/dm-serif-text-v4-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/dm-serif-text-v4-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/dm-serif-text-v4-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/dm-serif-text-v4-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/dm-serif-text-v4-latin-regular.svg#DMSerifText') format('svg'); /* Legacy iOS */
}

/* dm-serif-text-italic - latin */
@font-face {
  font-family: 'DM Serif Text';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/dm-serif-text-v4-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/dm-serif-text-v4-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/dm-serif-text-v4-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/dm-serif-text-v4-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('/fonts/dm-serif-text-v4-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/dm-serif-text-v4-latin-italic.svg#DMSerifText') format('svg'); /* Legacy iOS */
}

/* quattrocento-sans-regular - latin */
@font-face {
  font-family: 'Quattrocento Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/quattrocento-sans-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/quattrocento-sans-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/quattrocento-sans-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/quattrocento-sans-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/quattrocento-sans-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/quattrocento-sans-v13-latin-regular.svg#QuattrocentoSans') format('svg'); /* Legacy iOS */
}

/* quattrocento-sans-700 - latin */
@font-face {
  font-family: 'Quattrocento Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/quattrocento-sans-v13-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/quattrocento-sans-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/quattrocento-sans-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/quattrocento-sans-v13-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/fonts/quattrocento-sans-v13-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/quattrocento-sans-v13-latin-700.svg#QuattrocentoSans') format('svg'); /* Legacy iOS */
}

/* quattrocento-sans-italic - latin */
@font-face {
  font-family: 'Quattrocento Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/quattrocento-sans-v13-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/quattrocento-sans-v13-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/quattrocento-sans-v13-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/quattrocento-sans-v13-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('/fonts/quattrocento-sans-v13-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/quattrocento-sans-v13-latin-italic.svg#QuattrocentoSans') format('svg'); /* Legacy iOS */
}

