.blog-template {
	aside .ico-pencil {
		font-size: rem-calc(16);
		margin-bottom: 0;
	}
	.post-date {
		font-family: $alt-font;
		font-size: rem-calc(18);
		font-weight: $alt-bold;
		margin-bottom: 20px;
	}
	.h1 > a {
		&::after {
			display: none;
		}
	}
}

.blog-share-options {
	margin-top: 32px;
	.title {
		font-family: $alt-font;
		font-size: rem-calc(16);
		font-weight: $alt-bold;
		margin-bottom: 6px;
	}
}

.blog-post-title {
	padding: 0;
	border: 0;
	margin-bottom: 16px;
}

.blog-post-preview {
	h2 {
		font-size: rem-calc(30);
		line-height: 1.17;
		margin-bottom: 10px;
	}
	.read-more-link .button {
		margin-bottom: 0;
	}
}

@include breakpoint(large) {
	.blog-post-preview {
		h2 {
			font-size: rem-calc(34);
		}
	}
}

.blog-pagination {
	font-family: $alt-font;
	font-size: rem-calc(20);
	margin-top: 60px;
	text-align: right;
	span {
		line-height: 1;
	  	padding: 8px;
		display: inline-block;
	}
	a {
		line-height: 1;
		display: inline-block;
	}
	span.current {
		background-color: $white;
		border-radius: 5px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
		a {
			color: $brand-accent;
		}
	}
	img {
		height: 13px;
		margin: 0;
	}
	.pagination-prev img {
		transform: rotate(-180deg);
		margin-right: 5px;
	}
	.pagination-next img {
		margin-left: 5px;
	}
	.pagination-text a {
		display: flex;
		align-items: center;
	}
}

.blog-list-link {
	margin-bottom: 22px;
	position: relative;
	.icon-link {
		width: 24px;
		height: 24px;
		transform: rotate(180deg);
		margin: 0 5px 0 0;
	}
	a {
		display: flex;
		align-items: center;
	}
}