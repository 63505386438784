.gift-card-list {
	display: grid;
	grid-gap: 30px 20px;
	@include breakpoint(450px) {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	}
	@include breakpoint(900px) {
		grid-gap: 40px;
	}
	img {
		margin-bottom: 0;
	}
	a {
		max-width: 400px;
		margin: 0 auto;
	}
}