.state-locations-list {
	display: grid;
	grid-template-columns: 1fr;
	@include breakpoint(840px) {
		grid-template-columns: 1fr 1fr;
	}
}

.state-location {
	position: relative;
	display: flex;
	img {
		max-width: none;
		width: 100%;
		box-shadow: none;
		border: none;
		border-radius: 0;
	}
	a {
		width: 100%;
		color: $white;
		display: block;
	}
	a {
		&:hover, &:focus {
			.state-location-text {
				background-color: $brand-accent;
				box-shadow: 0 0 20px rgba(0,0,0, .9);
			}
		}
	}
	h2 {
		font-size: rem-calc(24);
		color: $white;
		margin: 0;
		display: inline-block;
		display: flex;
		align-items: center;
		.icon {
			margin: 0 12px 0 0;
		}
	}
	img {
		margin-bottom: 0;
	}
}

.state-location-text {
	max-width: 250px;
	width: 100%;
	height: 150px;
	background-color: rgba($brand, .85);
	border-radius: $border-radius;
	box-shadow: $box-shadow;
	padding: 10px 20px;
	position: absolute;
	top: 50%;
	left: 50%;
	transition: background-color .3s;
	transform: translate(-50%, -50%);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	.icon {
		color: $white;
		margin-bottom: 10px;
	}
	.icon svg {
		width: auto;
		height: 50px;
	}
}

@include breakpoint(550px) {

	.state-location {
		h2 {
			font-size: rem-calc(36);
		}
	}

	.state-location-text {
		max-width: 325px;
		height: 220px;
		.icon svg {
			height: 100px;
		}
	}
}

// Store location grid
.store-location-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	grid-gap: 30px;
	padding: 32px 16px 0;
	.text-sml {
		line-height: 1.3;
		display: inline-block;
	}
	.button {
		margin-bottom: 0;
	}
}


@include breakpoint(840px) {
	.state-locations-list .col:last-child .store-location-grid {
		border-left: solid 1px $tan;
	}
	.store-location-grid-item {
		min-height: 430px;
		display: flex;
		flex-direction: column;
		.button {
			margin-top: auto;
			align-self: flex-start;
		}
	}
}