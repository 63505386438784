/*------------------------------------*\
    @group Exit Intent Modal
\*------------------------------------*/
#exit-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1300;
	.underlay {
	    width: 100%;
	    height: 100%;
	    background-color: rgba(#3266b0, 0.85);
	    position: absolute;
	    top: 0;
	    left: 0;
	    animation: modal-fadein 0.4s;
	}

	.modal {
		background-color: $light-tan;
	    width: 95%;
	    height: 100%;
	    max-width: 425px;
	    max-height: 555px;
	    border: solid 1px $tan;
	    border-radius: $border-radius;
	    z-index: 100;
	    position: absolute;
	    margin: auto auto;
	    top: 0;
	    bottom: 0;
	    right: 0;
	    left: 0;
	    animation: modal-popin 0.4s;
	}
	.modal-inner {
	    height: 100%;
	    position: relative;
	}
	.modal-body {
		background-color: $light-tan;
	    max-width: 650px;
	    padding: 24px;
	    margin: 0 auto;
	}
	.modal-form {
	    max-width: 380px;
	}
	.modal-close {
	    width: 30px;
	    height: 30px;
	    font-size: 30px;;
	    font-weight: bold;
	    color: $dark-gray;
	    text-align: center;
	    display: block;
	    line-height: 1;
	    cursor: pointer;
	    position: absolute;
	    top: 6px;
	    right: 6px;
	    z-index: 1001;
	}
	h3 {
	    font-size: 26px;
	    font-weight: 700;
	    line-height: 1.2;
	    margin: 0 0 10px;
	}
	p {
	    margin: 0 0 20px;
	}
	img {
		box-shadow: none;
		border: none;
		border-radius: 0;
		margin-bottom: 48px;
	}
	.check-options {
		font-size: 14px;
		line-height: 1.2;
		margin-bottom: 12px;
		display: flex;
		input[type="checkbox"] {
			margin: 0 5px 0;
		}
	}
	.button {
		background-color: $brand-accent;
		&:hover, &:focus {
			background-color: $brand-alt;
		}
	}
	.newsletter-subscribe-form {
		background: none;
		padding: 0;
		box-shadow: none;
	}
}


@keyframes modal-fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes modal-popin {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    85% {
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
@media #{$medium-up} {
    #exit-modal .modal {
    	background-image: url('/images/modals/flowers_closeup.jpg');
    	background-color: $light-tan;
    	background-repeat: no-repeat;
    	background-position: left top;
        max-width: 700px;
        overflow: hidden;
    }
    #exit-modal .modal-body {
    	width: 50%;
		left: 50%;
		top: 50%;
		transform: translateY(-50%);
        padding: 30px;
        margin: 0;
		position: relative;
    }
    #exit-modal h3 {
        font-size: 26px;
    }
}
/* @end */

@media #{$medium-up} and (-webkit-min-device-pixel-ratio: 1.3),
#{$medium-up} and (-o-min-device-pixel-ratio: 13/10),
#{$medium-up} and (min-resolution: 120dpi) {
	#exit-modal .modal {
		background-image: url('/images/modals/flowers_closeup@2x.jpg');
		background-size: 350px 555px;
	}
}