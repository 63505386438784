.container-white {
	background-color: $white;
	padding: 20px;
	margin-bottom: 30px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
	border-radius: $border-radius;
	> *:last-child {
		margin-bottom: 0;
	}
}

.container-blue {
	background-color: $brand;
	color: $white;
	padding: 20px;
	margin-bottom: 30px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
	border-radius: $border-radius;
	h2 {
		color: $white;
		.icon {
			color: $light-blue;
		}
	}
	> *:last-child {
		margin-bottom: 0;
	}
}