.social-media-callout-wrapper {
	text-align: center;
	&.align-left {
		text-align: left;
	}
}
.social-media-callout {
	background: $white;
	padding: 30px;
	margin-bottom: 20px;
	border-radius: 4px;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.01);
	overflow: hidden;
	display: inline-flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 20px 30px;
	img {
		box-shadow: none;
		border: 0;
		border-radius: 0;
		padding: 0;
		margin: 0;
	}
	.heading {
		font-size: rem-calc(20);
		color: $main-color;
	}
	.text {
		> *:last-child {
			margin-bottom: 0;
		}
	}
	.list {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 20px 30px;
	}
}

.social-media-callout.small {
	padding: 20px;
	gap: 20px;
	.heading {
		font-size: rem-calc(18);
	}
	.list {
		gap: 20px 15px;
	}
}

.social-media-callout.full-width {
	width: 100%;
	justify-content: flex-start;
}