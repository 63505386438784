.gallery {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	justify-content: center;
	grid-gap: 30px;
	margin-bottom: 30px;
	img {
		margin-bottom: 0;
	}
}

.gallery.full-width {
	@include xy-gutters;
}