.bg-image-section {
	background: url('https://www.datocms-assets.com/33130/1610569738-womanmaskinspectingyellowpot.jpg?w=640&auto=format&bri=-30') no-repeat #041f02;
	background-size: cover;
	min-height: 400px;
	color: #FFFFFF;
	display: flex;align-items: center;
	justify-content: center;
	&.bg-pink-flower {
		background-image: url('https://www.datocms-assets.com/33130/1626800914-newstateprefooterpatternweguaranteesuccess.jpg?w=640&auto=format&bri=-10');
	}
}
.bg-image-section .inner {
	max-width: 700px;
	text-align: center;
	padding-top: 45px;
	padding-bottom: 45px;
}
.bg-image-section h2 {
	font-size: rem-calc(32);
	color: #FFF;
	line-height: 1;
	text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
	margin-bottom: 16px;
	@include breakpoint(medium) {
		font-size: 48px;
	}
}
.bg-image-section p {
	font-size: 20px;
	text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}

@include breakpoint(medium) {
	.bg-image-section {
		background-image: url('https://www.datocms-assets.com/33130/1610569738-womanmaskinspectingyellowpot.jpg?w=1000&auto=format&bri=-30');
		&.bg-pink-flower {
			background-image: url('https://www.datocms-assets.com/33130/1626800914-newstateprefooterpatternweguaranteesuccess.jpg?w=1000&auto=format&bri=-10');
		}
	}
}

@include breakpoint(large) {
	.bg-image-section {
		background-image: url('https://www.datocms-assets.com/33130/1610569738-womanmaskinspectingyellowpot.jpg?w=2000&auto=format&bri=-30');
		&.bg-pink-flower {
			background-image: url('https://www.datocms-assets.com/33130/1626800914-newstateprefooterpatternweguaranteesuccess.jpg?w=2000&auto=format&bri=-10');
		}
	}
}