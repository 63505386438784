.main-sidebar {
	.sidebar-inner {
		background: $white;
		padding: 20px;
		border-radius: 5px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
	}
	h2 {
		font-size: rem-calc(18);
		margin-bottom: 10px;
		img {
			margin: 0 8px 0 0;
		}
	}
	p {
		font-size: rem-calc(16);
		line-height: 1.5;
		margin-bottom: 10px;
	}
	.item {
		padding-bottom: 20px;
		border-bottom: solid 2px $light-tan;
		margin-bottom: 20px;
		&.no-divider, &:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border: 0;
		}
	}
	.social-icons {
		ul {
			margin: 0;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
		}
		li {
			margin-right: 10px;
			margin-bottom: 0;
		}
		img {
			margin: 0;
		}
	}
}

@include breakpoint(medium) {
	.main-sidebar {
		.sidebar-inner {
			display: grid;
			grid-column-gap: 30px;
			grid-template-areas: "main-sidebar-primary main-sidebar-stack-1"
								 "main-sidebar-primary main-sidebar-stack-2"
								 "main-sidebar-primary main-sidebar-stack-3";
		}
		.item {
			&.primary {
				grid-area: main-sidebar-primary;
			}
			&.stack-1 {
				grid-area: main-sidebar-stack-1;
			}
			&.stack-2 {
				grid-area: main-sidebar-stack-2;
			}
			&.stack-3 {
				grid-area: main-sidebar-stack-3;
			}
		}
	}
}

@include breakpoint(900px) {
	.main-sidebar .sidebar-inner {
		display: block;
	}
}