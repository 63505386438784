/*----------------------------------------------------------*\
    Lists

    In addition to standard list styles, a list with icons
    applied as ::before pseudo-elements is implemented.
    To add additional icons, follow the examples below.
    The split-list-* classes will make your list into
    multiple columns on larger views while remaining a
    single column on smaller views.

\*----------------------------------------------------------*/

ul {
	padding: 0;
	margin: 0 0 1.25em;
	li {
		line-height: 1.5;
		padding: 0 0 0 25px;
		margin: 0 0 14px;
		list-style: none;
		position: relative;
		&::before {
			content: "";
			background: url('/images/icons/leaf.svg') no-repeat left center;
			color: $brand-accent;
			width: 16px;
			height: 19px;
			display: block;
			position: absolute;
			left: 0;
			top: 2px;
		}
		&:last-child {
			margin-bottom: 0 !important;
		}
	}
	li ul {
		margin: .666em 0 .75em;
		li {
			padding-left: 35px;
			&::before {
				background: $brand-alt;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				top: 8px;
				left: 17px;
			}
		}
	}
	li ul li ul li {
		&::before {
			background: $brand;
		}
	}
}
ol {
	margin-bottom: 1.25em;
	li {
		line-height: 1.5;
		margin: 0 0 14px;
	}
	ol {
		list-style: lower-alpha;
	}
	ol ol {
		list-style: lower-roman;
	}
}

ul.ico-pencil li {
	&::before {
		background: url('/images/icons/pencil.svg') no-repeat left center;
	}
}

ul.bare {
	li {
		background: none;
		padding-left: 0;
		position: relative;
		&::before {
			display: none;
		}
	}
}

@include breakpoint(medium) {
	ul.split-list-2,
	ol.split-list-2 {
		margin-left: 0;
		padding-left: 0;
		overflow: hidden;
	}
	ul.split-list-2 li,
	ol.split-list-2 li {
		float: left;
		width: 50%;
		padding-right: 5%;
	}
	ul.split-list-2 li:nth-child(2n+1),
	ol.split-list-2 li:nth-child(2n+1) {
		clear:left;
	}
	ol.split-list-2 li {
		margin-right: 2%;
	}
	ul.split-list-3,
	ol.split-list-3 {
		padding-left: 0;
		margin-left: 0;
		overflow: hidden;
	}
	ul.split-list-3 li,
	ol.split-list-3 li {
		float: left;
		width: 33%;
		padding-right: 2%;
	}
	ol.split-list-3 li {
		margin-right: 2%;
	}
	ul.split-list-3 li:nth-child(3n+1),
	ol.split-list-3 li:nth-child(3n+1) {
		clear:left;
	}
}

/*------------------------------------*\
    @group Nav Reset
\*------------------------------------*/
nav ul {
	list-style: none;
	padding: 0;
	margin: 0;
	li {
		background: none;
		padding: 0;
		margin: 0;
		&::before {
			display: none;
		}
	}
	li ul {
		margin: 0;
	}
	li ul li {
		background: none;
		margin: 0;
	}
	li ul li ul {
		display: none !important;
	}
	li ul li ul li {
		background: none;
	}
}
/* @end */