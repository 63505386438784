/*----------------------------------------------------------*\
   Spacing Sections

   Sections can be used to increase whitespace between
   content sections as needed. The recommended method is
   wrapping the content in a div and applying the class
   with the spacing you want. The -full suffix will apply
   padding to the top and bottom which is well-suited for
   content areas that span the full width of the screen
   and use a background color.

\*----------------------------------------------------------*/

.content-section-full {
	padding: 30px 0 $full-section-spacing-small;
}
.intro-section {
	padding-bottom: $intro-section-spacing-small;
}
.content-section {
	padding-bottom: $content-section-spacing-small;
}
.content-section-top {
	padding-top: $content-section-spacing-small;
}
.content-sub-section {
	padding-bottom: $content-sub-section-spacing-small;
}
.content-sub-section-top {
	padding-top: $content-sub-section-spacing-small;
}
.narrow-section {
	max-width: 800px;
	&.center {
		margin: 0 auto;
	}
}

.extra-narrow-section {
	max-width: 650px;
}

@media screen and #{breakpoint(medium)} {
	.content-section-full {
		padding: 60px 0 $full-section-spacing;
	}
	.intro-section {
		padding-bottom: $intro-section-spacing;
	}
	.content-section {
		padding-bottom: $content-section-spacing;
	}
	.content-section-top {
		padding-top: $content-section-spacing;
	}
	.content-sub-section {
		padding-bottom: $content-sub-section-spacing;
	}
	.content-sub-section-top {
		padding-top: $content-sub-section-spacing;
	}
}