.grid-col-2 {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	grid-column-gap: 60px;
}

.grid-2 {
	max-width: 450px;
	margin-left: auto;
	margin-right: auto;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 60px;
	@include breakpoint(800px) {
		max-width: 100%;
		grid-template-columns: 1fr 1fr;
	}
}

.grid-3 {
	max-width: 330px;
	margin-left: auto;
	margin-right: auto;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 40px;
	> * {
		justify-self: center;
	}
	@include breakpoint(600px) {
		max-width: 100%;
		grid-template-columns: 1fr 1fr;
	}
	@include breakpoint(900px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

.grid-4 {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px;
	> * {
		justify-self: center;
	}
	@include breakpoint(500px) {
		max-width: 100%;
		grid-template-columns: 1fr 1fr;
	}
	@include breakpoint(700px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
	@include breakpoint(900px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}

.location-info-grid {
	max-width: 500px;
	margin: 0 auto;
	display: grid;
	gap: 45px 60px;
	.gift-card-callout {
		margin: 0;
		.button {
			margin-bottom: 0;
		}
	}
	.container-white {
		margin-bottom: 0;
	}
	.store-info-col {
		i {
			line-height: 1.5;
			display: inline-block;
		}
		> *:last-child {
			margin-bottom: 0;
		}
	}
}

@include breakpoint(650px) {
	.location-info-grid {
		max-width: 100%;
		gap: 30px 30px;
		grid-template-columns: 1fr 1fr;
		.hours-col {
			display: flex;
		}
	}
}

@include breakpoint(850px) {
	.location-info-grid {
		gap: 40px 60px;
	}
}