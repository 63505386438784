.page-content {
	background-color: $light-tan;
	padding: 40px 0 60px;
}

// @include breakpoint(large) {
// 	.page-content {
// 		background: url('/images/cssimg/sidebar_succulents.png') no-repeat right bottom $light-tan;
// 	}
// 	@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
// 	only screen and (-o-min-device-pixel-ratio: 13/10),
// 	only screen and (min-resolution: 120dpi) {
// 		.page-content {
// 			background-image: url('/images/cssimg/sidebar_succulents@2x.png');
// 			background-size: 453px 688px;
// 		}
// 	}
// }