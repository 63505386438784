//Accordionjs Base CSS
.accordionjs {
  position: relative;
  list-style: none;
}
.accordionjs .acc_section {
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.accordionjs .acc_section.acc_active > .acc_content {
  display: block;
}

// Custom accordion styling
.accordion {
	max-width: 750px;
	font-size: rem-calc(16);
	border-radius: 5px;
	overflow: hidden;
	margin-bottom: 40px;
	dt {
		background: $brand;
		font-family: $heading-font;
		font-size: rem-calc(24);
		font-weight: $heading-normal;
		color: $white;
		padding: 10px 20px;
		line-height: 1;
		cursor: pointer;
		margin: 0;
		transition: background-color .15s;
		&:hover, &:focus-visible {
			background: $brand-accent;
		}
	}
	.acc_active dt {
		background: $brand-accent;
	}
	dd {
		background-color: $white;
		padding: 20px;
		overflow: hidden;
		> *:last-child {
			margin-bottom: 0;
		}
	}
	.accordion-item {
		border-bottom: 1px solid $white;
		&:last-child {
			border-bottom: none;
		}
	}
}

.center {
	margin-left: auto;
	margin-right: auto;
}