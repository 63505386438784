.email-subscribe-callout-extra-space {
    margin-bottom: 44px;
}

.email-subscribe-callout {
    background: $white;
    color: $brand;
    box-shadow: 0 0 10px rgba(0,0,0,.05);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 30px;
    @include breakpoint(large) {
        padding-left: 30px;
        padding-right: 30px;
    }
    a {
        text-decoration: underline;
        color: $brand;
    }
    form {
        display: grid;
    }
    .heading {
        font-family: $heading-font;
        font-size: rem-calc(28);
        font-weight: $heading-bold;
        color: $brand;
        line-height: 1;
        margin: 0 0 10px;
        @include breakpoint(large) {
            font-size: rem-calc(34);
            line-height: 1.17;
            margin-bottom: 8px;
        }
    }
    .intro {
        margin-bottom: 20px;
        @include breakpoint(large) {
            text-align: center;
        }
    }
    label {
        font-family: $main-font;
        font-size: rem-calc(14);
        color: $brand;
        line-height: 1.28;
        .text {
            display: block;
            margin-bottom: 2px;
        }
    }
    label.checkbox-wrapper {
        font-weight: $main-normal;
        margin-bottom: 14px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        input {
            margin-bottom: 0;
        }
    }
    input[type="email"],
    input[type="text"] {
        height: 43px;
        border-radius: 0;
        margin-top: 0;
    }
    .button {
        margin-bottom: 0;
    }
    .email-subscribe-error {
        color: red;
        font-weight: $main-bold;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 0;
    }
}

@include breakpoint(500px) {
    .email-subscribe-callout {
        form {
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 20px;
        }
        .disclaimer-field {
            grid-column: 1 /-1;
        }
        .submit-field {
            grid-column: 1 /-1;
        }
    }
}

@include breakpoint(large) {
    .email-subscribe-callout {
        form {
            max-width: 765px;
            margin: 0 auto;
            grid-template-areas: "email phone submit" "disclaimer disclaimer disclaimer";
            grid-template-columns: 1fr 1fr auto;
        }
        input[type="email"],
        input[type="text"] {
            margin-bottom: 10px;
        }
        .email-field {
            grid-area: email;
        }
        .phone-field {
            grid-area: phone;
        }
        .disclaimer-field {
            grid-area: disclaimer;
            margin-bottom: 0;
        }
        label.checkbox-wrapper {
            justify-content: flex-start;
        }
        .submit-field {
            grid-area: submit;
        }
        .button {
            margin-top: 18px;
            margin-bottom: 10px;
        }
    }
}

.email-subscribe-callout.dark-bg {
    background-color: $brand;
    h2 {
        color: $white;
    }
    label {
        color: $white;
    }
    a {
        color: $white;
    }
}

.email-subscribe-callout.vertical {
    max-width: 600px;
    margin: 0 auto;
    .heading {
        font-size: rem-calc(28);
        line-height: 1.14;
        margin-bottom: 20px;
    }
    form {
        gap: 20px;
        input {
            margin-bottom: 0;
        }
    }
    .checkbox-wrapper {
        margin: 0;
    }
    .button {
        margin: 0;
    }
    .errors {
        background: #FFE6E6;
        border-radius: 5px;
        p {
            font-size: rem-calc(14);
            font-weight: $main-normal;
            color: #D70202;
            padding: 10px;
            line-height: 1.2;
            margin: 20px 0 0;
            text-align: left;
        }
        a {
            font-weight: $main-bold;
            color: #D70202;
        }
    }
}

@include breakpoint(500px) {
    .email-subscribe-callout.vertical {
        form {
            grid-template-columns: 1fr;
        }
        .disclaimer-field {
            grid-column: 1 /-1;
        }
        .submit-field {
            grid-column: 1 /-1;
        }
    }
}

@include breakpoint(large) {
    .email-subscribe-callout.vertical {
        padding: 30px;
        form {
            max-width: 765px;
            margin: 0 auto;
            grid-template-areas: "email" "phone" "disclaimer" "submit";
            grid-template-columns: 1fr;
        }
    }
}