.image-tile-grid {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px;
}

.image-tile-grid {
	.image {
		box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
		border-radius: 5px;
		overflow: hidden;
		transition: .3s box-shadow;
		&:hover, &:focus {
			box-shadow: 0 0px 10px rgba(0,0,0,.5);
			h2, h3 {
				background-color: $brand-accent;
			}
		}
	}
	h2, h3 {
		background-color: $brand;
		font-size: rem-calc(24);
		line-height: 1;
		color: $white;
		padding: 10px 14px 12px;
		margin: 0;
		transition: background-color .3s;
	}
	img {
		margin: 0;
		border: none;
		box-shadow: none;
		border-radius: 0;
	}
	p {
		margin: 10px 0 0;
	}
	a {
		text-decoration: none;
	}
}

@include breakpoint(medium) {
	.image-tile-grid {
		grid-template-columns: 1fr 1fr;
	}
}

@include breakpoint(large) {
	.image-tile-grid {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

.image-tile-grid-labels {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	justify-content: center;
	grid-gap: 20px;
}

.image-tile-grid-labels-item {
	margin: 0 auto;
	position: relative;
	.button {
		white-space: nowrap;
		margin: 0;
		display: inline-block;
		position: absolute;
		left: 50%;
		bottom: 40px;
		transform: translateX(-50%);
	}
	img {
		width: 100%;
		height: 100%;
		border-radius: 0;
		border: 0;
		margin: 0;
	}
}