.minimal-cards {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-gap: 30px;
}

.minimal-card {
	background: $white;
	width: 100%;
	max-width: 325px;
	padding: 20px;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
	margin: 0 auto;
	> *:last-child {
		margin-bottom: 0;
	}
	.heading {
		font-size: rem-calc(24);
		color: $brand;
		line-height: 1.25;
		margin: 0 0 10px;
	}
	.icon {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;
		span {
			color: $brand-alt;
			display: flex;
		}
	}
}