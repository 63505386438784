/*----------------------------------------------------------*\
    Variables

    This file contains all (non-Foundation) globally-scoped
    variables used throughout the Sass files. We strongly
    encourage sticking to these variables instead of
    hard-coded values as often as possible.

\*----------------------------------------------------------*/

//Font families
$sans-serif-font: "Quattrocento Sans",palatino,arial,sans-serif;
$serif-font: "DM Serif Text",georgia,serif;
$heading-font: $serif-font;
$main-font: $sans-serif-font;
$alt-font: $heading-font;

//Font weights
$main-normal: 400;
$main-bold: 700;
$alt-normal: 400;
$alt-bold: 700;
$heading-normal: $alt-normal;
$heading-bold: $alt-bold;

//Font sizes
$font-sizes: (
  small: (rem-calc(16), 1.25),
  default: (rem-calc(18), 1.5),
  large: (rem-calc(24), 1.33),
);


//Styleguide colors
$brand:				 #3266b0;
$brand-accent:		 #EF9C2A;
$brand-alt: 		 #44A231;
$brand-alt-accent: 	 #FFFFFF;
$blue:				 #54a1e4;
$light-blue:		 #accdf5;
$tan:                #dfdbd8;
$light-tan:          #f7f3ef;
$white:              #FFFFFF;
$black:              #000000;
$dark-green:		 #2B661F;
$dark-gray:			 #525251;
$off-white:			 #fefdfc;

$colors: (
	'brand':			 $brand,
	'brand-accent':		 $brand-accent,
	'brand-alt':         $brand-alt,
	'brand-alt-accent':  $brand-alt-accent,
	'blue':         	 $blue,
	'light-blue':        $light-blue,
	'tan':  			 $tan,
	'light-tan':  		 $light-tan,
	'white':             $white,
	'off-white':         $off-white,
	'black':             $black,
	'dark-gray':         $dark-gray
);

//Gradients, for use on backgrounds only
$gradients: (
	'white': linear-gradient(180deg, rgba(231, 231, 231, 1) 0%, rgba(255, 255, 255, 1) 100%)
);

//Transparent background colors
$brand-trans:        rgba($brand,0.8);
$black-trans:        rgba($black,0.7);

// Main body color, use when adding this color elsewhere (form fields as an example)
$main-color: $dark-gray;

//Link colors
$link-base: $brand;
$link-active: $brand-accent;

//Social media brand colors
$social: (
	'twitter': 		 #00aced,
	'facebook': 	 #3b5998,
	'googleplus': 	 #dd4b39,
	'pinterest': 	 #cb2027,
	'linkedin': 	 #007bb6,
	'youtube': 		 #bb0000,
	'vimeo': 		 #aad450,
	'instagram': 	 #517fa4,
	'quora': 		 #a82400,
	'foursquare': 	 #0072b1,
	'stumbleupon': 	 #EB4823,
	'soundcloud': 	 #ff3a00
);

//Shadows
$text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
$text-shadow-light: 1px 1px 1px rgb(255,255,255);
$box-shadow: 0 0 10px rgba(0,0,0,.05);

//Rounded corners
$border-round: 1000px;
$border-radius: 5px;

//Section spacing
$main-spacing:						20px;
$full-section-spacing-small: 		60px;
$content-section-spacing-small: 	60px;
$content-sub-section-spacing-small: 30px;
$intro-section-spacing-small: 		30px;
$full-section-spacing: 				90px;
$content-section-spacing: 			90px;
$content-sub-section-spacing: 		45px;
$intro-section-spacing: 			45px;

//Hero backgrounds
$hero-bg: (
	'home-orange': test
);

$hero-bg-color: (
	'home-orange': #ff9900
);