.hero-slider {
	background-color: $brand-accent;
	.slide-content {
		background-color: rgba($black, .6);
		max-width: 630px;
		color: $white;
		padding: 20px;
		border-radius: 5px;
	}
	.inner {
		max-width: 100%;
		@include breakpoint(800px) {
			padding-left: 50px;
			padding-right: 50px;
		}
	}
	.button {
		margin-bottom: 0;
	}
	.slide {
		height: 500px;
		background: url('https://www.datocms-assets.com/33130/1606346072-storeaisleplants.jpg?q=80&auto=format') no-repeat center center;
		background-size: cover;
		position: relative;
		@include breakpoint(medium) {
			height: 700px;
		}
		.inner {
			height: 100%;
			display: flex;
			align-items: center;
		}
		h2 {
			font-size: rem-calc(32);
			font-weight: $heading-normal;
			color: $white;
			line-height: 1.1;
			margin: 0 0 8px;
			text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
			@include breakpoint(medium) {
				font-size: rem-calc(40);
			}
		}
		p {
			text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
		}
	}
	.slick-dots {
		width: auto;
		display: inline-block;
		left: 10px;
		bottom: 20px;
		@include breakpoint(800px) {
			left: 50px;
		}
		li {
			background-color: $white;
			width: 14px;
			height: 14px;
			border-radius: 50%;
			margin: 0 10px;
			display: inline-block;
			&:hover, &:focus {
				background-color: $brand-alt;
			}
			&.slick-active {
				background-color: $brand-accent;
			}
			&::before {
				display: none;
			}
		}
		button {
			width: 14px;
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
			padding: 0;
			border-radius: 50%;
			&::before {
				width: 14px;
				height: 14px;
			}
		}
	}
}


@include breakpoint(800px) {
	.hero-slider {
		.slide {
			&::before {
				content: '';
				background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
				width: 100%;
				max-width: 700px;
				height: 100%;
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 5;
			}
		}
		.slide-content {
			background-color: transparent;
			border-radius: 0;
			padding: 0;
			position: relative;
			z-index: 20;
		}
	}
}

@include breakpoint(1100px) {
	.hero-slider {
		.slide {
			&::before {
				max-width: 900px;
			}
		}
	}
}

.hero-slider .slide {
	&.bg-ca-fruit-roses-slide {
		@include hero-slider-image('https://www.datocms-assets.com/33130/1736995524-slider-hero-ca-fruit-rose-more.jpg', #3a4f32);
	}
	&.bg-ca-roses-slide {
		@include hero-slider-image('https://www.datocms-assets.com/33130/1642194311-roses_slider_bareroot_packaged_peach.jpg', #e28034);
	}
	&.bg-ca-houseplants-slide {
		@include hero-slider-image('https://www.datocms-assets.com/33130/1629217832-cahomeslider2houseplants.jpg',#2d4827);
	}


	&.bg-az-houseplants-winter-slide {
		@include hero-slider-image('https://www.datocms-assets.com/33130/1701835306-az_hero_slider_houseplants_winter.jpg', #69a26f);
	}
	&.bg-az-fall-flowers-slide {
		@include hero-slider-image('https://www.datocms-assets.com/33130/1726180952-fall-flowers-az-hero-2-2024.jpg', #be0014);
	}
	&.bg-az-succulents-cacti-slide {
		@include hero-slider-image('https://www.datocms-assets.com/33130/1701835910-az_hero_succulents_cacti_hero_slider.jpg', #899d37);
	}
}