.card-image-list {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px;
	margin-bottom: 32px;
	@include breakpoint(medium) {
		grid-template-columns: 1fr 1fr;
	}
}

.card-image-list-item {
	background: $white;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
	overflow: hidden;
	h2 {
		margin-bottom: 10px;
	}
	p {
		margin-bottom: 12px;
	}
	img {
		margin: 0;
		border-radius: 0;
		border: 0;
		box-shadow: none;
	}
	.text {
		padding: 20px;
	}
	.arrow-link {
		font-size: rem-calc(18);
		&::after {
			line-height: 24px;
		}
	}
}