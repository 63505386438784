// Standard roadblock modal
.roadblock-modal {
	width: 100%;
	height: 100vh;
	padding: 10px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 4500;
	.modal-content{
		background: $white;
		width: 100%;
		height: auto;
		max-width: 660px;
		margin: 0 auto;
		border-radius: 6px;
		overflow: hidden;
		position: relative;
		overflow: auto;
		h2 {
			font-size: rem-calc(28);
			color: $brand;
			line-height: 1;
			margin-bottom: 10px;
		}
		p {
			font-size: 1rem;
		}
		label {
			font-family: $main-font;
		}
		input {
			height: 40px;
			border-width: 1px;
			margin-top: 2px;
			margin-bottom: 20px;
			&::placeholder {
				color: $black;
			}
		}
		.email-subscribe-error {
			font-size: rem-calc(14);
			font-weight: $main-bold;
			color: red;
			line-height: 1.3;
		}
		.checkbox-wrapper {
			display: flex;
			align-items: start;
			gap: 10px;
			input {
				height: auto;
				margin: 0;
			}
			.text {
				font-size: rem-calc(14);
				font-weight: $main-normal;
				line-height: 1.28;
			}
		}
		.disclaimer {
			font-size: rem-calc(14);
			line-height: 1.5;
		}
		.close-button-wrapper {
			position: absolute;
			right: 10px;
			top: 10px;
			button {
				width: 24px;
				height: 24px;
				background: $brand;
				color: $white;
				padding: 7px;
				line-height: 1;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				cursor: pointer;
				transition: background-color .3s;
				&:hover, &:focus-visible {
					background-color: $brand-accent;
				}
				svg {
					width: 10px;
					height: 10px;
				}
			}
		}
		.submit-field {
			text-align: center;
			padding: 20px 0 10px;
		}
		.close-text {
			font-size: rem-calc(14);
			text-align: center;
			button {
				color: $brand;
				cursor: pointer;
				text-decoration: underline;
				transition: color .3s;
				&:hover, &:focus {
					color: $brand-accent;
				}
			}
		}
	}

	.modal-content-image {
		overflow: hidden;
		display: none;
		img, video {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: top right;
			margin: 0;
		}
	}

	.modal-content-text {
		padding: 20px;
		&.column-content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 20px;
		}
		.text-middle {
			text-align: center;
			margin-top: auto;
			> *:last-child {
				margin-bottom: 0;
			}
		}
		.text-footer {
			margin-top: auto;
			> *:last-child {
				margin-bottom: 0;
			}
		}
	}
}


@include breakpoint(700px) {
	.roadblock-modal {
		.modal-content {
			display: flex;
			align-items: stretch;
			> * {
				flex-basis: 50%;
			}
		}
		.modal-content-image {
			display: block;
		}
		.modal-content-text {
			width: 100%;
			display: flex;
			align-items: center;
		}
	}
}