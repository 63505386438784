.corporate-home-template {
	.page-content {
		padding-top: 90px;
	}
	h1 {
		text-align: center;
		border: 0;
		padding-bottom: 0;
		margin-bottom: 30px;
	}
	.subheading {
		font-family: $heading-font;
		font-size: rem-calc(24);
		font-weight: $heading-bold;
		text-align: center;
		margin-bottom: 30px;
	}
	.logo-section {
		padding-top: 65px;
	}
	.page-content {
		padding: 0;
	}
	.content-logo {
		margin-bottom: 45px;
		img {
			margin: 0;
		}
	}
}