.event-card-section {
	h2 {
		margin-bottom: 12px;
	}
}

.event-card-section-heading {
	margin-bottom: 30px;
	a {
		font-size: 1rem;
		font-weight: $main-bold;
	}
}

.event-cards {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px;
	@include breakpoint(medium) {
		grid-template-columns: 1fr 1fr;
	}
	@include breakpoint(large) {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

.event-card {
	background-color: $white;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
	border-radius: $border-radius;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.event-card-header {
	img {
		box-shadow: none;
		border: 0;
		border-radius: 0;
		margin: 0;
	}
	h3 {
		background-color: $brand;
		color: $white;
		line-height: 1.2;
		padding: 15px 20px;
		margin: 0;
	}
}

.event-card-content {
	font-size: 1rem;
	padding: 10px 20px 30px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.event-card-date-cost {
	font-weight: $main-bold;
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
}

.event-card-details {
	padding-top: 20px;
	border-top: solid 2px $tan;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	p {
		margin-bottom: 5px;
	}
	.title {
		font-weight: $main-bold;
		color: $brand-alt;
		text-transform: uppercase;
		margin-right: 10px;
		display: inline-block;
	}
	.button {
		margin: auto 0 0;
		justify-self: flex-end;
	}
}

.event-card-details-list {
	margin-bottom: 16px;
}

.event-card-no-available-events {
	font-size: 1rem;
	border-radius: 5px;
	border: solid 2px $tan;
	padding: 30px;
	h3 {
		font-size: rem-calc(20);
		line-height: 1.25;
		margin-bottom: 15px;
		.icon {
			margin-right: 9px;
			vertical-align: -2px;
			display: inline-block;
		}
	}
	.info {
		border-bottom: solid 2px $tan;
		margin-bottom: 22px;
	}
}

.event-card-no-available-events.single-item {
	max-width: 400px;
}

@include breakpoint(800px) {
	.event-card-no-available-events {
		display: flex;
		.info {
			flex-basis: 50%;
			padding-right: 45px;
			border-bottom: 0;
			border-right: solid 2px $tan;
			margin-bottom: 0;
		}
		.newsletter {
			flex-basis: 50%;
			padding-left: 45px;
		}
	}

	.event-card-no-available-events.single-item {
		display: block;
		.newsletter {
			padding: 0;
		}
	}
}


// Horizontal Event Cards
.horizontal-event-cards > .horizontal-event-card:last-child {
	margin-bottom: 0;
}

.horizontal-event-card {
	max-width: 560px;
	margin-bottom: 50px;
	margin-left: auto;
	margin-right: auto;
}

.horizontal-event-card-content {
	background-color: $white;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
	border-radius: $border-radius;
	overflow: hidden;
}

.horizontal-event-card-header {
	padding: 0 0 10px;
	h3 {
		color: $brand;
		line-height: 1.2;
		margin: 0 0 10px;
	}
	p {
		margin-bottom: 0;
		margin-top: -5px;
	}
}

.horizontal-event-card-type {
	font-size: 1rem;
	line-height: 1;
	margin-bottom: 0;
	display: flex;
	justify-content: flex-end;
	gap: 4px;
	align-items: center;
	position: absolute;
	bottom: 10px;
	right: 10px;
	.icon {
		color: $brand-accent;
	}
	svg {
		width: auto;
		height: 12px;
	}
}

.horizontal-event-card-info {
	background-color: #FAF8F6;
	> div {
		padding: 20px;
	}
	img {
		box-shadow: none;
		border: 0;
		border-radius: 0;
		margin: 0;
	}
	p {
		margin-bottom: 0;
	}
	.title {
		font-weight: $main-bold;
		margin-right: 10px;
		display: inline-block;
	}
	.button {
		margin: 10px 0 0;
		justify-self: flex-end;
	}
}

.horizontal-event-card-description {
	position: relative;
	padding: 20px 20px 30px;
	box-shadow: inset 0 4px 4px -2px rgba(0,0,0,.1);
	> *:last-child {
		margin-bottom: 0;
	}
	.text-container {
		max-height: 430px;
		transition: max-height .3s;
		padding-bottom: 1px;
		overflow: hidden;
		position: relative;
		.text {
			padding-bottom: 1px;
		}
	}
	.read-more-link {
		background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50.55%);
		width: 100%;
		padding: 20px;
		text-align: center;
		position: absolute;
		bottom: 0;
		margin: 0;
		.button {
			margin-bottom: 0;
		}
	}
}

@include breakpoint(850px) {
	.horizontal-event-card {
		max-width: 100%;
	}
	.horizontal-event-card-header {
		h3 {
			font-size: rem-calc(30);
			line-height: 1.1;
		}
	}
	.horizontal-event-card-content {
		display: flex;
	}
	.horizontal-event-card-info {
		flex-basis: 500px;
	}
	.horizontal-event-card-description {
		flex-basis: 570px;
		box-shadow: inset 4px 0 4px -2px rgba(0,0,0,.1);
	}
}

@include breakpoint(1000px) {
	.horizontal-event-card-info > div {
		padding: 30px;
	}
	.horizontal-event-card-type {
		bottom: 15px;
		right: 20px;
	}
	.horizontal-event-card-description {
		padding: 30px;
	}
}

// Event bar filter
.event-filter-bar {
	background-color: $off-white;
	display: flex;
	flex-wrap: wrap;
	gap: 5px 20px;
	padding: 20px;
	margin-bottom: 50px;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0,0,0,.05);
	p {
		font-weight: $main-bold;
		margin-bottom: 0;
	}
}

.event-filter-bar-item {
	display: flex;
	align-items: center;
	gap: 5px;
	input[type="checkbox"] {
		width: 16px;
		height: 16px;
		margin: 0;
	}
	label {
		font-size: rem-calc(18);
		margin: 0;
	}
	.total-events {
		font-size: rem-calc(14);
		color: $brand;
	}
}

.event-filter-bar-items {
	display: flex;
	flex-wrap: wrap;
	gap: 10px 20px;
}