.media-object-overlap {
	margin: 0 auto;
	position: relative;
	.text {
		background: $white;
		padding: 30px;
		box-shadow: $box-shadow;
		border-radius: $border-radius;
		margin-bottom: -40px;
		position: relative;
		z-index: 50;
		.heading {
			font-size: rem-calc(28);
		}
		.button {
			margin-bottom: 0;
		}
	}
	.media {
		padding-left: 30px;
		text-align: right;
		img {
			margin-bottom: 0;
		}
	}
}

@include breakpoint(400px) {
	.media-object-overlap {
		position: relative;
		.text {
			max-width: 80%;
			margin: 0;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

@include breakpoint(750px) {
	.media-object-overlap {
		position: relative;
		.text {
			max-width: 550px;
			position: absolute;
			left: 0;
		}
		.media {
			text-align: right;
			padding-left: 10%;
			img {
				margin-bottom: 0;
			}
		}
	}
}

.media-object {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-gap: 60px;
	margin-bottom: 90px;
	&:last-child {
		margin-bottom: 0;
	}
	.text {
		align-self: center;
		.button {
			margin-bottom: 0;
		}
		.heading {
			font-size: rem-calc(34);
			line-height: 1.18;
			margin-bottom: 8px;
		}
	}
	.media {
		img {
			margin: 0;
		}
	}
}

.media-object.flip {
	.text {
		grid-column-start: 1;
		grid-row-start: 1;
	}
	.media {
	}
}

.media-object-thirds {
	max-width: 400px;
	margin: 0 auto 40px;
}

@include breakpoint(700px) {
	.media-object-thirds {
		max-width: 100%;
		display: flex;
		.media {
			flex-basis: 48%;
			order: 1;
			flex-shrink: 1;
		}
		.text {
			flex-basis: 48%;
			padding-right: 4%;
			order: 0;
			flex-shrink: 2;
			flex-grow: 1;
		}
	}
	.media-object-thirds.flip {
		.media {
			order: 0;
		}
		.text {
			order: 0;
			padding-right: 0;
			padding-left: 4%;
		}
	}
}

@include breakpoint(850px) {
	.media-object-thirds {
		.media {
			flex-basis: 40%;
		}
		.text {
			flex-basis: 60%;
			padding-right: 3%;
		}
	}
}

@include breakpoint(1000px) {
	.media-object-thirds {
		.media {
			flex-basis: auto;
			flex-shrink: 0;
		}
		.text {
			flex-basis: auto;
			padding-right: 3%;
		}
	}
}